// components/Layout.js
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {
  const router = useRouter();
  const [footerVisible, setFooterVisible] = useState(true);

  useEffect(() => {
    // 개인정보 보호와 계약 화면은 Footer 안보이게 함.
    if (router.asPath === '/privacy' || router.asPath === '/terms')
      setFooterVisible(false);
    else setFooterVisible(true);
  }, [router]);

  return (
    <div className='w-full h-screens'>
      <Head>
        <meta property='og:title' content='Enkino 주식회사' key='title' />
        <title>엔키노 홈페이지 입니다</title>
      </Head>
      <Header />
      <div className='w-full h-screen pt-16'>{children}</div>
      {footerVisible ? <Footer /> : null}
    </div>
  );
};

export default Layout;
