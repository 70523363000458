import React, { useEffect } from "react";
import Script from "next/script"
import Layout from "components/Layout";
import useSettings from "stores/settings";
import { CssBaseline } from "@mui/material";

import "../styles/main.css";
import "../styles/home.css";
import "../styles/aisoColor.css";
import "../styles/aiso.css";
import "../styles/all.min.css";
import "../styles/test.scss";
import "../styles/glass.scss"; // 글래스모피즘 샘플
import "node_modules/react-modal-video/scss/modal-video.scss";

const SECRET_CODE = "sdkjkdlsAESRcdslkch";

function MyApp({ Component, pageProps }) {
  const settings = useSettings();

  useEffect(() => {
    const tokenValue = window.localStorage.getItem("token");
    if (tokenValue === "" || tokenValue === null) {
      settings.isSignin = false;
      settings.email = "";
      settings.name = "";
      settings.id = "";
      settings.sns = "";
      settings.photo = "";
      settings.photoURL = "";
      settings.isAdmin = false;
    } else {
      try {
        var decoded = jwt.verify(tokenValue, SECRET_CODE);

        settings.isSignin = true;
        settings.email = decoded.email;
        settings.phone = "";
        settings.name = decoded.name;
        settings.sns = decoded.sns;
        settings.photo = decoded.photo;
        settings.photoURL = decoded.photoURL;
        settings.isAdmin = decoded.isAdmin;
      } catch (err) {
        console.log(err);
        settings.isSignin = false;
        settings.email = "";
        settings.name = "";
        settings.id = "";
        settings.sns = "";
        settings.photo = "";
        settings.photoURL = "";
        settings.isAdmin = false;
        window.localStorage.removeItem("token");
      }
    }
  });

  return (
    <>
         <Script
        src="https://dapi.kakao.com/v2/maps/sdk.js?appkey=cb74202902f841350fe62c8f7bbe4338&libraries=services,clusterer&autoload=false"
        strategy="beforeInteractive"
      />
      <Layout>
      <CssBaseline />
      <Component {...pageProps} />
    </Layout>
    </>
    
  );
}

export default MyApp;
