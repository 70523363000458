import { useContext, createContext } from "react";
import { observable, configure } from "mobx";

configure({
  enforceActions: "never",
});

const context = createContext(
  observable({
    // mobile , desktop
    isBigScreen: false,
    isMobile: false,
    isPotrait: false,
    windowSize: null,
    isChanged: false,
    currentMenu: "home",
    // ==========================================
    // 일반 로그인
    isAdmin: false,
    isSignin: false,

    name: "",
    email: "",
    phone: "",
    sns: "facebook",
    photoURL: "",
    id:"",

    // token:
    //   "a.eyJ1c2VyIjp7ImlkIjoiY2tjajE4NjB0MDAwMG11OWhtZGxidHlncyIsImVtYWlsIjoibWhqODhAbmF0ZS5jb20iLCJuYW1lIjoi66y47ZiE7KCVIiwicGhvbmUiOiIiLCJzbnNQcm92aWRlciI6ImZhY2Vib29rIn0sImlhdCI6MTU5NDU5NzM3OCwiZXhwIjoxNTk1MjAyMTc4fQ.ElrZbYJwfgruz9KwuwBy3wojF7PGakW4ruIFWrgybyM", // email: "mhj88@nate.com",
    // displayName: "문현정",
    // email: "mhj88@nate.com",
    // phone: "",
    // snsProvider: "facebook",
    // photoUrl: "",
    // uid:"",
    // ==========================================

    // ==========================================
    // 배포용
    // id: "",
    // isSignin: false,
    // token: "",
    // email: "",
    // displayName: "",
    // phone: "",
    // snsProvider: "",
  })
);

const useSettings = () => useContext(context);
export default useSettings;
