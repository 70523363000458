import React from "react";
import Link from "next/link";

const Footer = () => {
  return (
    <footer className="w-full bg-white border-t px-5">
      <div className="container mx-auto max-w-7xl py-3 sm:py-6 flex flex-wrap md:flex-no-wrap justify-between items-center text-sm">
        <div className="md:p-0 text-center md:text-right text-xs">&copy;2022 Enkino. All rights reserved.</div>
        <div className="flex flex-row">
          {/* <div className="pt-2 sm:pt-0 text-center md:text-right text-xs">
            <Link href="/privacy">
              <a className="text-black no-underline hover:underline">Privacy Policy</a>
            </Link>
          </div>
          <div className="pt-2 sm:pt-0 text-center md:text-right text-xs">
            <Link href="/terms">
              <a className="text-black no-underline hover:underline ml-4">Terms &amp; Conditions</a>
            </Link>
          </div> */}
          <div className="pt-2 sm:pt-0 text-center md:text-right text-xs">
            <Link href="https://enkino.com/company">
              <span className="text-black no-underline hover:underline ml-4">Contact Us</span>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
